<template>
  <div class="user-add">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span>{{ $route.meta.title }}</span>
    </div>
    <div>
      <div class="next-form-item-label">
        <label>权限信息</label>
      </div>
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="form"
        :rules="rules"
        class="demo-form-inline"
        label-position="top"
      >
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="form.name" style="width: 400px">
            <span slot="suffix">{{ domainName }}</span>
          </el-input>
        </el-form-item>
        <el-form-item label="所属域" prop="name">
          <el-select v-model="form.domain_id" placeholder="请选择">
            <el-option
              v-for="item in domain_list"
              :key="item.id"
              :label="item.name"
              :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属应用" prop="name">
          <el-select v-model="form.client_uuid" placeholder="请选择">
            <el-option
              v-for="item in client_list"
              :key="item.client_uuid"
              :label="item.client_name"
              :value="item.client_uuid" />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="权限JSON" style="min-width: 80%;" required>
              <json-editor ref="jsonEditor" v-model="resources" @onJsonChange="onJsonChange" />
              <!-- <json-editor :key="edit_ing" ref="jsonEditor" v-model="resources" read-only style="border: 1px solid #DCDFE6;width:100%" @changed="changed" /> -->
              <!-- <div v-else style="white-space: pre-wrap;line-height: 20px">{{resources}}</div> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                :autosize="{ minRows: 4, maxRows: 4}"
                style="width: 500px"
                type="textarea"
                :rows="2"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="btn-con" style="float: left">
            <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
            <el-button @click="previous">取消</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { domain_drop_down_menu } from '@/api'
import { get_permission_client_info, add_permission } from '@/api/policies'
import jsonEditor from '@/components/jsoneditor'

export default {
  components: { jsonEditor },
  data () {
    return {
      resources: '',
      domain_list: [],
      client_list: [],
      form: {},
      rules: {
        // name: [{ required: true, message: '请输入登录名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    domainName () {
      const obj = this.domain_list.find(item => item.id == this.form.domain_id) || {}
      return obj.name ? '@' + obj.name : ''
    }
  },
  created () {
    this.get_domain_list()
    this.get_permission_client_info_list()
  },
  methods: {
    async get_permission_client_info_list () {
      const { data } = await get_permission_client_info({ is_client_admin: this.$store.state.userInfo.is_client_admin })
      console.log(data, '2222')
      this.client_list = data
    },

    onJsonChange (val, has) {
      this.resources = JSON.stringify(val)
    },
    get_domain_list () {
      domain_drop_down_menu().then(res => {
        console.log(res)
        this.domain_list = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            client_uuid: this.form.client_uuid,
            name: this.form.name,
            domain_id: this.form.domain_id,
            remark: this.form.remark,
            permission_json: typeof (this.resources) === 'object' ? JSON.stringify(this.resources, null, 2) : this.resources
          }
          add_permission(params).then(res => {
            this.$message({
              showClose: true,
              message: 'success',
              type: 'success'
            })
            this.$router.push('/policies')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/policies')
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="less">
.user-add {
  .el-radio-group .el-radio {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
